import { useCallback } from 'preact/hooks';
import { Modal } from 'components/modal/Modal';
import { ModalHeader } from 'components/modal/ModalHeader';
import { localize } from 'i18n/localize';
import { Icon } from 'components/atoms/Icon';
import { Pill } from 'components/atoms/Pill';
import LogoSbazar from 'components/logoSbazar/LogoSbazar';
import { assignEmailSignal } from 'components/modal/assignEmail/signals';

const $t = localize();

function HeaderLogo() {
	return (
		<div>
			<LogoSbazar className="hidden md:block w-[150px]" />
		</div>
	);
}

const formatDisplayName = (displayName = '') => {
	const hiddenCharacter = '…';
	const replaceCharacter = '...';

	return displayName
		.split(hiddenCharacter)
		.join(replaceCharacter)
		.replace(/(.{4})(.{3})(.{3})(.{3})/, '$1 $2 $3 $4')
		.split(replaceCharacter)
		.join(hiddenCharacter);
};

export function AssignEmailModal() {
	const data = assignEmailSignal.value;
	if (!data || !data.userData) {
		return;
	}
	const { title, loginSourceComponent, userData } = data;

	const loginCloseCallback = useCallback(() => {
		assignEmailSignal.done();
		window.removeEventListener('login-done', loginCloseCallback);
		window.removeEventListener('login-close', loginCloseCallback);
	}, []);

	const onClose = useCallback(() => {
		assignEmailSignal.close();
	}, [assignEmailSignal.peek()]);

	const onAssign = useCallback(() => {
		window.addEventListener('login-done', loginCloseCallback);
		window.addEventListener('login-close', loginCloseCallback);
		window.login.assignEmail.open({ sourceComponent: loginSourceComponent });
	}, []);

	const onLoginToDifferentAccount = useCallback(() => {
		window.addEventListener('login-done', loginCloseCallback);
		window.addEventListener('login-close', loginCloseCallback);
		window.login.open({ sourceComponent: loginSourceComponent });
	}, []);

	return (
		<Modal
			className="bg-neutral-white w-[600px] max-w-full px-6 py-6 overflow-auto max-h-max absolute md:relative bottom-0"
			onClickAway={onClose}
			onClose={onClose}
		>
			<ModalHeader className="mb-0 md:mb-8" header={HeaderLogo} onClose={onClose} />
			<h1 className="text-2xl mb-6 text-center md:text-left">{title}</h1>
			<div className="bg-gray-light-background rounded-2xl py-3 px-4 text-sm flex gap-4 items-center mb-6">
				<Icon type="phone_iphone" size={32} fill className="text-dark-blue-25"></Icon>
				<div>
					<div>{userData.name ?? $t.assignEmail.userNameFallback}</div>
					{userData.displayName && <div>{formatDisplayName(userData.displayName)}</div>}
				</div>
			</div>
			<Pill pillType="primary" onClick={onAssign} className="block w-full py-2 md:py-3 mb-6">
				{$t.assignEmail.assignEmailButton}
			</Pill>
			<div className="text-dark-blue-50 text-center text-sm">
				{$t.assignEmail.or}{' '}
				<button className="link" onClick={onLoginToDifferentAccount}>
					{$t.assignEmail.loginButton}
				</button>
			</div>
		</Modal>
	);
}
